import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import BlogList from "./BlogList";
const pluralize = require("pluralize");

class MostPopularTemplate extends React.Component {
  render() {
    let ignore = [];

    // *do not* use group to prevent duplicate entries to same slug, instead this is managed through
    // code.

    let { data, slug, renderMode, renderSize, renderWeight } = this.props;
    let mostPopularConfig = data.markdownRemark;

    if (
      mostPopularConfig &&
      mostPopularConfig.frontmatter &&
      mostPopularConfig.frontmatter.filter
    ) {
      ignore = mostPopularConfig.frontmatter.filter;
    }

    const { edges: posts } = data.allMarkdownRemark;

    const { edges: videoPageViews } = data.videos;
    const { edges: blogPageViews } = data.blogs;
    let { authors } = data;
    let { header } = data;

    if (header && header.layout && header.layout.render) {
      if (header.layout.render.popular) {
        renderMode = header.layout.render.popular;
      }
      if (header.layout.render.size) {
        renderSize = header.layout.render.size;
      }
      if (header.layout.render.weight) {
        renderWeight = header.layout.render.weight;
      }
    }

    let postData = [];
    let pageViews = [];

    if (slug.indexOf("video") !== -1) {
      pageViews = videoPageViews;
    } else {
      pageViews = blogPageViews;
    }

    // pageViews sort by views
    pageViews = pageViews.sort((a, b) =>
      parseInt(a.node.views) < parseInt(b.node.views) ? 1 : -1
    );

    // remove duplicates
    let path = "",
      singleton = [],
      pageViewsFiltered = [],
      pageViewsCounter = [];

    pageViews.forEach((post) => {
      post.node.path = addTrailingSlash(post.node.path);
      path = post.node.path;
      if (pageViewsCounter[path]) {
        pageViewsCounter[path] =
          parseInt(post.node.views) + parseInt(pageViewsCounter[path]);
      } else {
        pageViewsCounter[path] = parseInt(post.node.views);
      }

      if (
        typeof pageViewsCounter[path] !== undefined &&
        singleton[path] !== 1
      ) {
        // process ignores so you can re-focus the
        // most popular
        ignore.forEach((ignore) => {
          if (String(path).match(ignore)) {
            //console.log("match");
          } else {
            pageViewsFiltered.push(post);
            singleton[path] = 1;
          }
        });
      }
    });

    pageViewsFiltered.forEach(({ node: view }) => {
      posts.forEach(({ node: post }) => {
        if (
          view.path !== slug &&
          view.path !== addTrailingSlash(slug) &&
          view.path !== addTrailingSlash(slug) + "most-popular" &&
          view.path !==
            addTrailingSlash(slug) + addTrailingSlash("most-popular")
        ) {
          if (
            post.fields.slug === view.path ||
            post.fields.slug === addTrailingSlash(view.path)
          ) {
            post.frontmatter.views = view.views;
            postData.push({ node: post });
          }
        }
      });
    });

    // limit to max of 10 results
    postData = postData.slice(0, 10);
    authors = authors.nodes;
    //console.log(postData);
    return (
      <BlogList
        posts={postData}
        authors={authors}
        emptyMessage="We don't have any popular pages yet."
        title={`Popular Blog ${pluralize("Post", posts.length)}:`}
        renderMode={renderMode}
        renderSize={renderSize}
        renderWeight={renderWeight}
      />
    );
  }
}

MostPopular.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  slug: PropTypes.string,
};

export default function MostPopular(args) {
  return (
    <StaticQuery
      query={graphql`
        query MostPopularPageComponent {
          authors: allMarkdownRemark(
            filter: {
              frontmatter: { advanced: { templateKey: { eq: "author-post" } } }
            }
          ) {
            nodes {
              id
              fields {
                slug
              }
              frontmatter {
                name
              }
            }
          }
          markdownRemark(
            frontmatter: {
              advanced: {
                configKey: { eq: "most-popular" }
                templateKey: { eq: "configuration" }
              }
            }
          ) {
            frontmatter {
              filter
            }
          }
          blogs: allPageViews(
            limit: 10
            filter: { path: { regex: "//blog/[^0-9]/" } }
          ) {
            edges {
              node {
                views
                path
              }
            }
          }
          videos: allPageViews(
            limit: 10
            filter: { path: { regex: "//video/[^0-9]/" } }
          ) {
            edges {
              node {
                views
                path
              }
            }
          }
          allMarkdownRemark(
            filter: {
              frontmatter: {
                advanced: { templateKey: { in: ["blog-post", "video-post"] } }
              }
            }
          ) {
            edges {
              node {
                id
                frontmatter {
                  title
                  advanced {
                    templateKey
                  }
                  titles {
                    subtitle
                  }
                  creationDate
                  author
                  category
                  summary
                  flags {
                    published
                    featured
                  }
                  images {
                    hero {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          layout: FULL_WIDTH
                          placeholder: BLURRED
                        )
                      }
                    }
                  }
                }
                fields {
                  slug
                }
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <MostPopularTemplate
          data={data}
          count={count}
          slug={args.slug}
          renderMode={args.renderMode}
          renderSize={args.renderSize}
          renderWeight={args.renderWeight}
        />
      )}
    />
  );
}

function addTrailingSlash(url) {
  if (url.charAt(url.length - 1) !== "/") {
    url = url + "/";
  }
  return url;
}
