import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";

import Seo from "../components/Seo";
import FullWidthImage from "../components/FullWidthImage";

import DisplayHeroVideoOrImage from "../components/DisplayHeroVideoOrImage";
import MostPopular from "../components/MostPopular";
import DisplayTitleWithImage from "../components/DisplayTitleWithImage";
import DisplayContent from "../components/DisplayContent";
// eslint-disable-next-line
export const MostPopularPageTemplate = ({
  title,
  subtitle,
  footer,
  images,
  author,
  creationDate,
  breadcrumbs,
  options,
  body,
  helmet,
  previewContent,
  previewTitle,
  related,
  layout,
  videos,
  header,
}) => {
  let templateName = "most-popular-blog-page",
    heroVideo,
    heroImage,
    smallImage,
    footerImage;

  if (!layout) layout = [];

  if (images) {
    if (images.hero) {
      heroImage = getImage(images.hero) || images.hero;
    }
    if (images.footer) {
      footerImage = getImage(images.footer) || images.footer;
    }
    if (images.smallImage) smallImage = images.smallImage;
  }

  if (videos && videos.hero) heroVideo = videos.hero;
  if (!layout.position) layout.position = "below";

  const showTitle = options.showTitle;
  const showTitlesSwapped = options.showTitlesSwapped;

  let showDisplayContentHeader = true;
  if (
    !options.showDisplayContentHeader &&
    options.showDisplayContentHeader !== undefined &&
    options.showDisplayContentHeader !== null
  ) {
    showDisplayContentHeader = false;
  }

  let renderMode, renderSize, renderWeight;
  if (header && header.layout && header.layout.render) {
    if (header.layout.render.popular) {
      renderMode = header.layout.render.popular;
    }
    if (header.layout.render.size) {
      renderSize = header.layout.render.size;
    }
    if (header.layout.render.weight) {
      renderWeight = header.layout.render.weight;
    }
    layout = header.layout;
  }

  if (!author && previewTitle) {
    author = "[Not Set]";
  }

  return (
    <div>
      {helmet || null}

      <DisplayHeroVideoOrImage
        heroVideo={heroVideo}
        heroImage={heroImage}
        title={title}
        subtitle={subtitle}
        imgPosition="center"
        showTitle={showTitle}
        showTitlesSwapped={showTitlesSwapped}
      />

      <section className={`section section--gradient ${templateName}`}>
        <div className="container">
          <div className="content">
            <DisplayTitleWithImage
              smallImage={smallImage}
              title={title}
              subtitle={subtitle}
              showDisplayContentHeader={showDisplayContentHeader}
              breadcrumbs={breadcrumbs}
              header={header}
            />

            <DisplayContent
              id={templateName}
              previewContent={previewContent}
              body={body}
              layout={layout}
              element={
                <>
                  <MostPopular
                    slug="/blog/"
                    renderSize={renderSize}
                    renderMode={renderMode}
                    renderWeight={renderWeight}
                  />
                </>
              }
            />
          </div>
        </div>
      </section>

      {footerImage ? (
        <FullWidthImage
          img={footerImage}
          subheading={footer}
          imgPosition="center"
          className="footer-image-text"
          showTitle={true}
        />
      ) : null}
    </div>
  );
};

MostPopularPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string,
  images: PropTypes.shape({
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  author: PropTypes.string,
  creationDate: PropTypes.string,
  sortDate: PropTypes.string,
  flags: PropTypes.shape({
    published: PropTypes.bool,
    featured: PropTypes.bool,
  }),
  options: PropTypes.shape({
    showTitle: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    showComments: PropTypes.bool,
    showAuthorSummary: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showPopular: PropTypes.bool,
    showRelated: PropTypes.bool,
  }),
  breadcrumbs: PropTypes.shape({
    parent: PropTypes.string,
    parentSlug: PropTypes.string,
    grandparent: PropTypes.string,
    grandparentSlug: PropTypes.string,
  }),
  body: PropTypes.string,
  summary: PropTypes.string,
};

const MostPopularPage = ({ data }) => {
  const { markdownRemark: post } = data;
  let { config } = data;

  let imageSrc;

  if (
    post.frontmatter.images &&
    post.frontmatter.images.hero &&
    post.frontmatter.images.hero.childImageSharp
  ) {
    imageSrc =
      post.frontmatter.images.hero.childImageSharp.gatsbyImageData.images
        .fallback.src;
  }
  if (!post.frontmatter.titles) post.frontmatter.titles = {};
  //  config = config.frontmatter.site;
  //  site = site.siteMetadata.property;
  return (
    <Layout>
      <MostPopularPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.titles.subtitle}
        footer={post.frontmatter.titles.footer}
        images={post.frontmatter.images}
        author={post.frontmatter.author}
        creationDate={post.frontmatter.creationDate}
        options={post.frontmatter.options}
        breadcrumbs={post.frontmatter.breadcrumbs}
        body={post.html}
        helmet={
          <Seo
            title={post.frontmatter.title}
            summary={post.frontmatter.summary}
            image={imageSrc}
            article={false}
          />
        }
        header={config.frontmatter}
      />
    </Layout>
  );
};

MostPopularPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MostPopularPage;

export const mostPopularPageQuery = graphql`
  query MostPopularPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImageUrl
        }
        options {
          showTitle
          showTitlesSwapped
          showDisplayContentHeader
        }
        breadcrumbs {
          parent
          parentSlug
          grandparent
          grandparentSlug
        }
        summary
      }
    }
    config: markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "site" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      frontmatter {
        layout {
          columns
          render {
            size
            popular
            position
            separator
            weight
          }
        }
        breadcrumbs {
          positionClass
          separatorClass
          position
          showDate
          showCurrent
        }
      }
    }
    mostPopular: markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "most-popular" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      frontmatter {
        layout {
          position
          columns
          separator
        }
      }
    }
  }
`;
